import snakecase from 'lodash/snakeCase'
import { t } from '@zupr/i18n'

import {
    footwearMaterialGroups,
    clothingMaterialGroups,
} from '../../../constants'

const formatMaterial = ({ value, label, group }) => {
    const prefix = `${snakecase(group)}_`
    const percentage = value //${value.endsWith('.0') ? value.replace('.0', '') : value}
    return `${percentage}% ${t(label.replace(prefix, ''))}`
}

export const materialsForGroup = ({ group, ...props }) =>
    Object.keys(props)
        .filter((key) => key.startsWith(snakecase(group)))
        .map((key) => ({
            label: key,
            value: props[key],
        }))

export const materialGroups = ({ type, ...props }) => {
    const groups =
        type === 'clothing' ? clothingMaterialGroups : footwearMaterialGroups
    return groups.filter((group) =>
        materialsForGroup({ group, ...props }).some((group) => !!group.value)
    )
}

const useColorsAndMaterials = ({
    color_name,
    size,
    material_description,
    heel_height,
    removable_footbed,
    type,
    ...props
}) => {
    const specs = []

    if (color_name) {
        specs.push({
            label: 'product-page.title.color',
            value: color_name,
        })
    }

    if (size) {
        specs.push({
            label: 'product-page.title.size',
            value: size,
        })
    }

    if (heel_height) {
        specs.push({
            label: 'product-page.title.size',
            value: `${heel_height} mm`,
        })
    }

    if (typeof removable_footbed === 'boolean') {
        specs.push({
            label: t('product-page.title.removable_footbed'),
            value: t(removable_footbed ? 'Yes' : 'No'),
        })
    }

    if (material_description) {
        specs.push({
            label: 'Material',
            value: material_description,
        })
    }

    const material_groups = materialGroups({ type, ...props })

    if (material_groups && material_groups.length) {
        material_groups.forEach((group) => {
            specs.push({
                label: t(`material.group.${snakecase(group)}`),
                value: materialsForGroup({ group, ...props })
                    .filter(({ value }) => !!value)
                    .map((props) => formatMaterial({ group, ...props }))
                    .join(','),
            })
        })
    }

    return specs
}

export default useColorsAndMaterials
