import { getName } from '@zupr/utils/countries'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const useTargetCountries = ({ target_countries }) => {
    if (!target_countries || !target_countries.length) return []
    return [
        {
            label: 'product-page.title.target-countries',
            value: target_countries
                .map((flag) =>
                    getName(flag, publicRuntimeConfig.NEXT_PUBLIC_LANGUAGE)
                )
                .join(', '),
        },
    ]
}

export default useTargetCountries
