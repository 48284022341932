import Link from 'next/link'
import React, { useState } from 'react'

import Trans from './trans'

interface ReadMoreProps {
    text: string
}

export const NewlineBreak = ({ text }: ReadMoreProps) => {
    if (typeof text !== 'string') return null
    return (
        <>
            {text.split('\n').map((part, i) => (
                <React.Fragment key={`${i}-${part}`}>
                    {part}
                    <br />
                </React.Fragment>
            ))}
        </>
    )
}

interface ReadMoreProps {
    text: string
    length?: number
    link?: string
    onReadMore?: (readMore: boolean) => void
}

const ReadMore = ({ text, length, onReadMore, link }: ReadMoreProps) => {
    const [showMore, setShowMore] = useState(false)

    const handleShowMore = () => {
        setShowMore(true)
        if (onReadMore) onReadMore(true)
    }

    const handleShowLess = () => {
        setShowMore(false)
    }

    if (showMore) {
        return (
            <p>
                <NewlineBreak text={text} />
                <br />
                <button
                    className="inline"
                    type="button"
                    onClick={handleShowLess}
                >
                    <Trans label="read less" />
                </button>
            </p>
        )
    }

    if (!text) return null

    if (link) {
        return (
            <p>
                {text.substring(0, length)}
                {`… `}
                <Link href={link}>
                    <a>
                        <Trans label="read more" />
                    </a>
                </Link>
            </p>
        )
    }

    if (text.length > length) {
        return (
            <p>
                {text.substring(0, length)}
                {`…`}
                <button
                    className="inline"
                    type="button"
                    onClick={handleShowMore}
                >
                    <Trans label="read more" />
                </button>
            </p>
        )
    }

    return (
        <p>
            {text.split('\n').map((part, i) => (
                <React.Fragment key={i}>
                    {part}
                    <br />
                </React.Fragment>
            ))}
        </p>
    )
}

export default ReadMore
