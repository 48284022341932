import moment from 'moment'
import languages from '@zupr/utils/languages'

const useBook = ({ book }) => {
    const specs = []
    if (!book) return specs

    if (book.subject) {
        specs.push({
            label: 'Onderwerp',
            value: book.subject,
        })
    }

    if (book.subtitle) {
        specs.push({
            label: 'Subtitel',
            value: book.subtitle,
        })
    }

    if (book.authors) {
        specs.push({
            label: book.authors.length === 1 ? 'Auteur' : 'Auteurs',
            value: book.authors.join(', '),
        })
    }

    if (book.language) {
        specs.push({
            label: 'Taal',
            value: languages.getNativeName(book.language),
        })
    }

    if (book.nur_description) {
        specs.push({
            label: 'Categorie',
            value: book.nur_description,
        })
    }

    if (book.audience) {
        specs.push({
            label: 'Doelgroep',
            value: book.audience,
        })
    }

    if (book.edition) {
        specs.push({
            label: 'Editie',
            value: book.edition,
        })
    }

    if (book.page_count) {
        specs.push({
            label: "Aantal pagina's",
            value: book.page_count,
        })
    }

    if (book.publish_date) {
        specs.push({
            label: 'Gepubliceerd',
            value: moment(book.publish_date).format('LL'),
        })
    }

    if (book.publisher) {
        specs.push({
            label: 'Uitgever',
            value: book.publisher,
        })
    }

    return specs
}

export default useBook
