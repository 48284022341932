const carbohydrates = ['sugars', 'polyols', 'starch']

const fats = [
    'saturated_fatty_acids',
    'unsaturated_fatty_acids',
    'polyunsaturated_fatty_acids',
]

const vitamins = [
    'vitamin_a',
    'vitamin_d',
    'vitamin_e',
    'vitamin_k',
    'vitamin_c',
    'thiamine',
    'riboflavine',
    'niacine',
    'vitamin_b6',
    'folic_acid',
    'vitamin_b12',
    'biotine',
    'pantothenic_acid',
]

const minerals = [
    'potassium',
    'chloride',
    'calcium',
    'phosphorus',
    'magnesium',
    'iron',
    'zinc',
    'copper',
    'fluoride',
    'selenium',
    'chrome',
    'molybdenum',
    'iodine',
]

const useNutrients = ({
    kJ_value,
    kJ_unit,
    kcal_value,
    kcal_unit,
    ...props
}) => {
    const specs = []

    const getCombined = (key) => {
        const value = parseFloat(props[`${key}_value`])
        const unit = props[`${key}_unit`]
        return `${value.toLocaleString('nl')} ${unit}`
    }

    if (kJ_value || kcal_value) {
        specs.push({
            label: 'food.label.energy',
            value: `${
                (kJ_value &&
                    `${parseFloat(kJ_value).toLocaleString(
                        'nl'
                    )} ${kJ_unit}`) ||
                ''
            }${(kJ_value && kcal_value && '/') || ''} ${
                kcal_value &&
                `${parseFloat(kcal_value).toLocaleString('nl')} ${kcal_unit}`
            }`,
        })
    }

    if (props.fats_value) {
        specs.push({
            label: `food.label.fats`,
            value: getCombined('fats'),
        })
    }

    if (fats.some((fat) => !!props[`${fat}_value`])) {
        fats.forEach((fat) => {
            if (!props[`${fat}_value`]) return
            specs.push({
                label: `food.label.${fat}`,
                value: getCombined(fat),
                className: 'child',
            })
        })
    }

    if (props.carbohydrates_value) {
        specs.push({
            label: `food.label.carbohydrates`,
            value: getCombined('carbohydrates'),
        })
    }

    if (
        carbohydrates &&
        carbohydrates.some((carbohydrate) => !!props[`${carbohydrate}_value`])
    ) {
        carbohydrates.forEach((carbohydrate) => {
            if (!props[`${carbohydrate}_value`]) return
            specs.push({
                label: `food.label.${carbohydrate}`,
                value: getCombined(carbohydrate),
                className: 'child',
            })
        })
    }

    if (props.fibers_value) {
        specs.push({
            label: `food.label.fibers`,
            value: getCombined('fibers'),
        })
    }

    if (props.proteins_value) {
        specs.push({
            label: `food.label.proteins`,
            value: getCombined('proteins'),
        })
    }

    if (props.salt_value) {
        specs.push({
            label: `food.label.salt`,
            value: getCombined('salt'),
        })
    }

    if (props.vitamins_value) {
        specs.push({
            label: `food.label.vitamins`,
            value: getCombined('vitamins'),
        })
    }

    if (vitamins && vitamins.some((vitamin) => !!props[`${vitamin}_value`])) {
        props.vitamins.forEach((vitamin) => {
            if (!props[`${vitamin}_value`]) return
            specs.push({
                label: `food.label.${vitamin}`,
                value: getCombined(vitamin),
                className: 'child',
            })
        })
    }

    if (props.minerals_value) {
        specs.push({
            label: `food.label.minerals`,
            value: getCombined('minerals'),
        })
    }

    if (minerals && minerals.some((mineral) => !!props[`${mineral}_value`])) {
        minerals.forEach((mineral) => {
            if (!props[`${mineral}_value`]) return
            specs.push({
                label: `food.label.${mineral}`,
                value: getCombined(mineral),
                className: 'child',
            })
        })
    }

    return specs
}

export default useNutrients
