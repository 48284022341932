import React from 'react'

import Trans from '../../../components/trans'
import Figure from '../../../components/figure'
import { t } from '@zupr/i18n'

const symbols = [
    'care_washing',
    'care_bleaching',
    'care_machine_drying',
    'care_natural_drying',
    'care_ironing',
    'care_professional',
]

const instructions = [
    'care_washing_instructions',
    'care_bleaching_instructions',
    'care_machine_drying_instructions',
    'care_natural_drying_instructions',
    'care_ironing_instructions',
    'care_professional_instructions',
    'care_extra_instructions',
]

const useWashAndCare = ({ care_description, ...data }) => {
    const specs = []

    if (!data) return specs

    symbols.forEach((key) => {
        if (data[key]) {
            specs.push({
                label: t(key, 'washAndCare'),
                value: t(data[key], 'washAndCare'),
                image: '/img/washandcare/' + data[key] + '.svg',
            })
        }
    })

    instructions.forEach((key) => {
        if (data[key]) {
            specs.push({
                label: t(key, 'washAndCare'),
                value: data[key]
                    .map((value) => t(value, 'washAndCare'))
                    .join(', '),
            })
        }
    })

    if (care_description) {
        specs.push({
            label: 'Extra instructions',
            value: care_description,
        })
    }

    return specs
}

// Display all the wash and care instructions and symbols.
const WashAndCare = ({
    care_washing,
    care_bleaching,
    care_machine_drying,
    care_natural_drying,
    care_ironing,
    care_professional,
    care_washing_instructions,
    care_bleaching_instructions,
    care_machine_drying_instructions,
    care_natural_drying_instructions,
    care_ironing_instructions,
    care_professional_instructions,
    care_extra_instructions,
    care_description,
}) => {
    return (
        <React.Fragment>
            <Symbols
                values={[
                    care_washing,
                    care_bleaching,
                    care_machine_drying,
                    care_natural_drying,
                    care_ironing,
                    care_professional,
                ]}
            />
            <Instructions
                values={[
                    care_washing_instructions,
                    care_bleaching_instructions,
                    care_machine_drying_instructions,
                    care_natural_drying_instructions,
                    care_ironing_instructions,
                    care_professional_instructions,
                    care_extra_instructions,
                ]}
            />
            {!!care_description && (
                <React.Fragment>
                    <h4>
                        <Trans label="Extra instructions" />
                    </h4>
                    <p>{care_description}</p>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default useWashAndCare
